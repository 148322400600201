import { useEffect } from "react";
import "../../style/pages/Team.scss";

const team = [
    {
        name: "Piet",
        title: "Directeur",
        description: "",
        imgUrl: "https://img.nwalraven.me/topassist/team/piet_720",
        contrastyImg: true,
    },
    {
        name: "Dennis",
        title: "Systeem- en netwerkbeheerder",
        description: "",
        imgUrl: "https://img.nwalraven.me/topassist/team/dennis_720",
    },
    {
        name: "Pravish",
        title: "Systeem- en netwerkbeheerder",
        description: "",
        imgUrl: "https://img.nwalraven.me/topassist/team/pravish_720",
    },
    {
        name: "Marjon",
        title: "Administratie en HR",
        description: "",
        imgUrl: "https://img.nwalraven.me/topassist/team/marjon_720",
    },
    {
        name: "Robin",
        title: "Office manager en telefonie",
        description: "",
        imgUrl: "https://img.nwalraven.me/topassist/team/robin_720",
        contrastyImg: true,
    },
    {
        name: "Nick",
        title: "Software engineer",
        description:
            "Van adverterende websites tot functionele web applicaties. Met kennis van de nieuwste technologieën en designs maak ik hier en daar wat mooie projecten.",
        imgUrl: "https://img.nwalraven.me/topassist/team/nick_720",
    },
    {
        name: "Thomas",
        title: "Stagiair",
        description: "",
        imgUrl: "https://img.nwalraven.me/topassist/team/thomas_720",
    },
];

export default function Team() {
    useEffect(() => {
        document.title = "Topassist - Top team";
    }, []);

    return (
        <div className="team-page">
            <meta name="description" content="Topassist - Ons team" />
            <div className="team">
                {team.map((member) => (
                    <div className="member" key={member.name}>
                        <img
                            className={member.contrastyImg ? "contrasty" : ""}
                            src={member.imgUrl}
                            width="1"
                            height="1"
                            alt="Employee"
                            loading="lazy"
                        />
                        <div className="details">
                            <h1>{member.name}</h1>
                            <h2>{member.title}</h2>
                            <p>{member.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
